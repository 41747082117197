<template>
    <div class="component-document-statistics relative">
        <div v-if="!vm.showStudentGrantDetails" class="mb-5">
            <page-header :headline="'Statistik for &quot;' + vm.documentName + '&quot;'" :enableBackButton="true" @backAction="fn.goBack()"></page-header>
            <div class="text-right mb-3">
                <Button v-if="!vm.fetchingData && (!vm.isStudentGrantDocument && !vm.isBatch)" icon="pi pi-download" class="p-button-info" label="Hent Data (CSV fil)" @click="fn.downloadDocumentData(documentTemplateId)" :disabled="vm.chartDocumentRecipientMembersData.completed === 0" />
            </div>
            <div v-if="!vm.fetchingData && vm.documentRecipientMembers && vm.documentRecipientMembers.length">
                <Message v-if="vm.isBatch" severity="info" class="mt-0">Bemærk at dette kun er modtagere af dokumentet fra den {{$filters.formatDateSimple(vm.documentRecipientMembers[0].created)}}. <span class="clickable p-text-bold" @click="fn.showAll">Klik her for at se alle modtagere</span> </Message>
                <Message v-if="vm.isBatch && vm.batchSettings.sendToMemberOnly || vm.batchSettings.sendToMemberRelationsOnly" severity="warn" class="mb-4">
                    <span v-if="vm.batchSettings.sendToMemberOnly">Der er kun sendt til elev</span>
                    <span v-if="vm.batchSettings.sendToMemberRelationsOnly">Der er kun sendt til forældre/værger</span>
                </Message>
                <div class="grid">

                    <div class="col-12 lg:col-5">
                        <Chart type="pie" :data="vm.chartDocumentRecipientMembersData" :options="vm.chartDocumentRecipientMembersOptions" style="max-width:400px" class="m-auto lg:m-0" />
                    </div>
                    <div class="col-12 lg:col-7">
                        <div class="flex mb-3 p-3">
                            <div><i class="pi pi-check-circle mr-4 mt-1" style="font-size: 2.5rem; color: #39bc6c;"></i></div>
                            <div>
                                <h2>{{vm.chartDocumentRecipientMembersData.completed}}%</h2>
                                Har færdiggjort dokumentet<span v-if="documentName"> "{{documentName}}"</span>
                                <div class="mt-3">
                                    <Button v-if="vm.isStudentGrantDocument" icon="pi pi-sync" label="Se indsendt data og eventuelle forskelle" class="p-button-success" @click="fn.gotoStudentGrantDataOverview" />
                                </div>
                            </div>
                        </div>

                        <div class="box flex" v-if="vm.chartDocumentRecipientMembersData.missing > 0">
                            <div><i class="pi pi-question-circle mr-4 mt-1" style="font-size: 2.5rem; color: #3B82F6; "></i></div>
                            <div>
                                <h2>{{vm.chartDocumentRecipientMembersData.missing}}%</h2>
                                Mangler at færdiggøre dokumentet.<br /><br />
                                <Button icon="pi pi-envelope" class="p-button-info" label="Send påmindelse" @click="fn.sendReminders" />
                            </div>
                        </div>

                    </div>
                </div>
                <DataTable ref="dt" stripedRows
                           :value="vm.documentRecipientMembers"
                           v-model:expandedRows="expandedRows"
                           dataKey="id"
                           sortField="created" :sortOrder="-1"
                           :paginator="false" :rows="10" :filters="filters"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="Viser {first} til {last} af {totalRecords} kontakter" responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between" :class="{'mt-5': vm.isStudentGrantDocument}">
                            <div class="mb-2 md:m-0 md:align-self-center"></div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Søg..." />
                            </span>
                        </div>
                    </template>
                    <Column class="column-state">
                        <template #body="slotProps">
                            <div v-if="!slotProps.data.owner.isActive" class="icon-wrapper warning" v-tooltip.top="'Kontakten ligger i papirkurven. Dokumentet vil blive arkiveret jf slettereglerne under indstillinger'"><i class="pi pi-trash"></i></div>
                            <div v-else-if="slotProps.data.sendSettings && slotProps.data.sendSettings.sendToMemberOnly" class="icon-wrapper info" v-tooltip.top="'Sender kun til elev'"><i class="pi pi-user-minus"></i></div>
                            <div v-else-if="slotProps.data.sendSettings && slotProps.data.sendSettings.sendToMemberRelationsOnly" class="icon-wrapper info" v-tooltip.top="'Sender kun til forældre/værger'"><i class="pi pi-user-minus"></i></div>
                        </template>
                    </Column>
                    <Column :expander="true" />
                    <Column field="owner.externalId" header="ID" :sortable="true"></Column>
                    <Column field="owner.firstname" header="Fornavn" :sortable="true">
                        <template #body="slotProps">
                            <strong :class="{'text-400': !slotProps.data.owner.isActive }">{{slotProps.data.owner.firstname}}</strong>
                        </template>
                    </Column>
                    <Column field="owner.lastname" header="Efternavn" :sortable="true">
                        <template #body="slotProps">
                            <strong :class="{'text-400': !slotProps.data.owner.isActive }">{{slotProps.data.owner.lastname}}</strong>
                        </template>
                    </Column>
                    <Column field="owner.email" header="E-mail" :sortable="false"></Column>
                    <Column header="Afsendt" field="created" :sortable="true">
                        <template #body="slotProps">
                            {{$filters.formatDateSimple(slotProps.data.created)}}
                        </template>
                    </Column>
                    <Column header="Opdateret" field="updated" :sortable="true">
                        <template #body="slotProps">
                            {{$filters.formatDateSimple(slotProps.data.updated)}}
                        </template>
                    </Column>
                    <Column header="Status" field="state" :sortable="true" class="text-center column-state">
                        <template #body="slotProps">
                            <Tag v-if="slotProps.data.state === 'PUBLISHED'" severity="success" value="Afsluttet"></Tag>
                            <Tag v-else-if="slotProps.data.state === 'READY_TO_PUBLISH'" value="Klar til afslutning"></Tag>
                            <Tag v-else-if="slotProps.data.state === 'READY_TO_SENDER_APPROVE'" severity="warning" value="Afventer godkendelse"></Tag>
                            <Tag v-else-if="slotProps.data.state === 'READY_TO_SIGN' || slotProps.data.state === 'SIGNING'">Igang {{slotProps.data.signingInfo}}</Tag>
                            <Tag v-else-if="slotProps.data.state === 'SENT'" severity="info" value="Afsendt"></Tag>
                            <Tag v-else-if="slotProps.data.state === 'INPUT_COMPLETED'" severity="info" value="Gemt"></Tag>
                            <Tag v-else-if="slotProps.data.state === 'DECLINED'" severity="danger" value="Afvist"></Tag>
                            <Tag v-else-if="slotProps.data.state === 'PREPARED'" severity="info" value="Kø til afsendelse"></Tag>
                            <Tag v-else severity="info" :value="slotProps.data.state"></Tag>
                        </template>
                    </Column>
                    <Column header="Påmindet" class="text-center" style="width:105px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.noOfTimesReminded > 0" class="align-items-center flex justify-content-center">
                                <i class="pi pi-bell" style="font-size: 20px;background: rgba(254, 252, 232, 0.95); border-radius: 50%;"></i>
                                <span class="ml-1">{{slotProps.data.noOfTimesReminded }}</span>
                            </div>
                            <i v-else class="pi pi-times" style="color: #EF4444; font-size: 20px "></i>
                        </template>
                    </Column>
                    <!--<Column class="text-right" style="width:205px;min-width:205px;">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.state === 'SENT' || slotProps.data.state === 'READY_TO_SIGN' || slotProps.data.state === 'INPUT_COMPLETED' || slotProps.data.state === 'SIGNING'"
                                    icon="pi pi-envelope" v-tooltip.top="'Send påmindelse'" class="p-button-info mr-1" @click="fn.sendReminder(slotProps.data)"></Button>
                            <Button icon="pi pi-eye" v-tooltip.top="'Se dokument'" class="mr-3" @click="fn.goToDocument(slotProps.data)"></Button>
                            <Button icon="pi pi-trash" v-tooltip.top="'Slet'" class="p-button-danger" @click="fn.confirmDelete(slotProps.data)"></Button>

                        </template>
                    </Column>-->
                    <Column :exportable="false" class="text-center d-print-none" style="width:80px">
                        <template #body="{data, index}">
                            <div class="card flex justify-content-center">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text py-0" @click="fn.toggleDocumentMenu(index, $event, data)" aria-haspopup="true" :aria-controls="`overlay_document-menu_${index}`" />
                                <Menu :ref="el => documentMenuRefs[index] = el" :id="`overlay_document-menu_${index}`" :model="documentMenu" :popup="true" />
                            </div>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div>
                            <div v-if="slotProps.data.signers && slotProps.data.signers.length > 0">
                                <DataTable :value="slotProps.data.signers" responsiveLayout="scroll">
                                    <Column field="member.emailHasBounced" class="column-state">
                                        <template #body="slotProps">
                                            <div v-if="slotProps.data.member.emailHasBounced" class="icon-wrapper error" v-tooltip.top="'Seneste afsendelse til e-mail adressen mislykkes'"><i class="pi pi-times-circle"></i></div>
                                            <div v-else-if="!slotProps.data.member.email" class="icon-wrapper warning" v-tooltip.top="'Ingen e-mail tilknyttet'"><i class="pi pi-exclamation-triangle"></i></div>
                                        </template>
                                    </Column>
                                    <Column field="member.firstname" header="Fornavn"></Column>
                                    <Column field="member.lastname" header="Efternavn"></Column>
                                    <Column field="member.email" header="E-mail"></Column>
                                    <Column field="state" header="Status" class="column-state">
                                        <template #body="slotProps">
                                            <Tag v-if="slotProps.data.state ==='UNSIGNED'" severity="info" value="Mangler at underskrive"></Tag>
                                            <Tag v-else-if="slotProps.data.state ==='DECLINED'" severity="danger" value="Afvist"></Tag>
                                            <Tag v-else severity="success" value="Underskrevet"></Tag>
                                        </template>
                                    </Column>
                                    <Column class="text-right column-state column-last" style="width: 85px;padding:.5rem">
                                        <template #body="{ data }">
                                            <Button v-if="data.state ==='UNSIGNED' && slotProps.data.state !== 'READY_TO_PUBLISH' && slotProps.data.state !== 'PUBLISHED' && !slotProps.data.hasSignatureOrderId" icon="pi pi-trash" class="p-button-danger" v-tooltip.top="'Fjern underskriver'" @click="fn.confirmDeleteSigner(slotProps.data, data)" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <div v-else>
                                <DataTable :value="slotProps.data.recipients" responsiveLayout="scroll">
                                    <Column field="member.emailHasBounced" class="column-state">
                                        <template #body="slotProps">
                                            <div v-if="slotProps.data.member.emailHasBounced" class="icon-wrapper error" v-tooltip.top="'Seneste afsendelse til e-mail adressen mislykkes'"><i class="pi pi-times-circle"></i></div>
                                            <div v-else-if="!slotProps.data.member.email" class="icon-wrapper warning" v-tooltip.top="'Ingen e-mail tilknyttet'"><i class="pi pi-exclamation-triangle"></i></div>
                                        </template>
                                    </Column>
                                    <Column field="member.firstname" header="Fornavn"></Column>
                                    <Column field="member.lastname" header="Efternavn"></Column>
                                    <Column field="member.email" header="E-mail"></Column>
                                    <Column field="state" header="Modtaget" class="text-right column-state column-last">
                                        <template #body="slotProps">
                                            <Tag v-if="!slotProps.data.member.email || slotProps.data.member.emailHasBounced" severity="danger" value="Nej"></Tag>
                                            <Tag v-else severity="info" value="Ja"></Tag>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </div>
                    </template>

                </DataTable>
            </div>
            <div v-else class="text-center">
                <Message v-if="!vm.fetchingData && documentTemplateId" severity="warn">Dokumentet er ikke blevet sendt til nogen brugere endnu</Message>
                <ProgressSpinner v-else class="my-3" />
            </div>
        </div>
        <ConfirmDialog group="confirmForceApproveDocument" :style="{'max-width': '600px', width: '100%'}">
            <div v-if="vm.currentDocument">Er du sikker på, at du vil afslutte dokumentet, selvom der stadig er personer, der mangler at underskrive?</div>
            <Message severity="info" :closable="false">Dokumentet afsluttes og forsegles. De personer, der mangler at underskrive, vil stadig være angivet som underskrivere, men det vil fremgå, at de ikke har underskrevet.</Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmDeleteSigner" :style="{'max-width': '600px', width: '100%'}">
            <div v-if="vm.confirmDeleteSignerMessage" v-html="vm.confirmDeleteSignerMessage"></div>
            <Message severity="warn" :closable="false">Personen kan ikke tilføjes igen efter fjernelse. Hvis personen senere skal underskrive, skal du sende et nyt dokument.</Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmDeleteDocument" :style="{'max-width': '600px', width: '100%'}">
            <div v-if="vm.currentDocument">Er du sikker på at du vil slette dokumentet for <b>{{vm.currentDocument.owner.firstname + ' ' + vm.currentDocument.owner.lastname}}</b>?</div>
            <Message severity="info" :closable="false">Dokumentet flyttes til papirkurven, hvor det kan gendannes inden for papirkurvens opbevaringsperiode.<br />Efterfølgende bliver det arkiveret jf slettereglerne under "indstillinger".</Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmSendReminder">
            <div v-if="vm.currentDocument">
                <div v-for="recipient in vm.currentDocument.recipients" :key="recipient.id">
                    <Message v-if="recipient.signingState === 'UNSIGNED' && !recipient.member.email" severity="warn" :closable="false"><strong>{{recipient.member.firstname}} {{recipient.member.lastname}}</strong> har ingen e-mail tilknyttet. Vi sender i stedet en påmindelse til alle andre relationer!</Message>
                </div>
            </div>
            <div class="align-items-center flex flex-1 justify-content-between mb-2 mt-5">
                <strong class="block secondary-label">Mailbesked</strong>
                <div class="field-checkbox mb-0 ml-2">
                    <Checkbox inputId="remember-email-text" v-model="vm.documentTemplate.reminderEmailTextRemember" :binary="true" />
                    <label for="remember-email-text" v-tooltip.top="'Skal beskeden gemmes til fremtidig brug?'">Husk besked <i class="pi pi-question-circle"></i></label>
                </div>
            </div>

            <div style="height:300px;">
                <editor :init="{height: 300, api_key: 'gpl',license_key: 'gpl',paste_preprocess: handlePastePreprocess,menubar: false,default_link_target: '_blank', quickbars_image_toolbar: false, quickbars_insert_toolbar: false}"
                        plugins="link lists quickbars"
                        toolbar="bold underline strikethrough removeformat | link"
                        v-model="vm.documentTemplate.reminderEmailText"
                        @change="vm.reminderTextChanged = true" />
            </div>
            <small class="block mt-3">
                Flettefelter, der kan bruges i mailbeskeden:<br />
                <strong>{elevnavn}</strong> = Indsætter elevens navn<br />
                <strong>{elevefternavn}</strong> = Indsætter elevens efternavn.<br />
                <strong>{elevadresse}</strong> = Indsætter elevens adresse, hvis udfyldt.<br /><br />
                <strong>{elevnummer}</strong> = Indsætter elevnummer<br /><br />
                <strong>{klassetrin}</strong> = Indsætter elevens klassetrin, også kendt som "Nuværende klasse" i KOMiT.<br /><br />
                <span>EKS: <em>kære {elevnavn} og forældre</em></span>
            </small>
            <div class="field-checkbox mb-0 flex justify-content-end">
                <Checkbox inputId="force-remind" v-model="vm.documentTemplate.forceRemindAllSigners" :binary="true" />
                <label for="force-remind" v-tooltip.top="'Vil du gerne sende en påmindelse til alle, også dem der allerede har udfyldt/underskrevet?'">Send til alle <i class="pi pi-question-circle"></i></label>
            </div>
        </ConfirmDialog>
        <div v-if="vm.submitting || vm.busy" class="overlay overlay-submitting">
            <ProgressSpinner />
        </div>
        <div v-if="vm.showStudentGrantDetails" class="member-details px-4 pb-4 bg-white border-round">
            <div class="bg-white flex justify-content-between align-items-center pt-4 pb-2 mb-3 sticky top-0 z-1" style="border-bottom: 2px solid rgb(231, 231, 242);">
                <h3 class="mb-0">Oversigt over indsendt data og forskelle</h3>
                <Button icon="pi pi-times" style="" class="p-button-rounded p-button-text p-button-lg" @click="vm.showStudentGrantDetails = false" />
            </div>
            <DataTable :value="vm.documentStudentGrantMembersExtendedData"
                       v-model:expandedRows="expandedRows"
                       v-model:filters="studentGrantNeedsReviewFilter"
                       responsiveLayout="scroll">
                <Column class="column-state">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.dataHasPendingChanges" class="icon-wrapper warning" v-tooltip.top="'Forskel i data'"><i class="pi pi-exclamation-triangle"></i></div>
                        <div v-else class="icon-wrapper success"><i class="pi pi-check"></i></div>
                    </template>
                </Column>
                <Column :expander="true" />
                <Column field="owner.externalId" header="Id" :sortable="true"></Column>
                <Column field="owner.firstname" header="Fornavn" :sortable="true"></Column>
                <Column field="owner.lastname" header="Efternavn" :sortable="true"></Column>
                <Column header="Kommune" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.communeCode || 'N/A'}}: {{slotProps.data.communeName }}
                    </template>
                </Column>
                <Column header="Hjemme-boende børn" class="text-center" headerStyle="width:120px;min-width:120px;">
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.otherChildrenAmount > 0 || slotProps.data.otherChildrenNames" :value="slotProps.data.otherChildrenAmount" size="large" severity="warning" v-tooltip.top="slotProps.data.otherChildrenNames"></Badge>
                        <div v-else>{{slotProps.data.otherChildrenAmount}}</div>
                    </template>
                </Column>
                <Column header="Kræver yderligere dok." class="text-center column-state" headerStyle="width:115px;min-width:115px;">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.requiresDocumentation || slotProps.data.workedAbroad">
                            <i v-if="slotProps.data.documentAssets && slotProps.data.documentAssets.length" class="pi pi-check align-items-center inline-flex justify-content-center" style="background: #F59E0B; color: white; width: 36px; height: 36px; font-size: 1.4rem; border-radius: 50%;" v-tooltip.top="'Dokumentation uploadet, se den ved at trykke på pilen til venstre for elevnummeret'"></i>
                            <i v-else-if="slotProps.data.workedAbroad && !slotProps.data.requiresDocumentation" class="pi pi-info-circle align-items-center inline-flex justify-content-center" style="color: #3b82f6; width: 36px; height: 36px; font-size: 2rem;" v-tooltip.top="'Vær opmærksom på, at der er svaret ja til arbejdet i udlandet, men ikke ved indkomst i udlandet'"></i>
                            <i v-else class="pi pi-check align-items-center inline-flex justify-content-center" style="background: #EF4444; color: white; width: 36px; height: 36px; font-size: 1.4rem; border-radius: 50%;" v-tooltip.top="'Ingen dokumentation uploadet'"></i>
                        </span>
                        <i v-else class="pi pi-minus"></i>
                    </template>
                </Column>
                <Column header="Familie-situation" class="text-center column-state" headerStyle="width:140px;min-width:140px;">
                    <template #body="slotProps">
                        <Tag v-if="slotProps.data.familySetup ==='NEW_PARTNER'" severity="warning" value="Bor hos 1 forældre + Ny samlever/ægtefælle"></Tag>
                        <Tag v-else-if="slotProps.data.familySetup ==='SINGLE'" severity="info" value="Bor hos 1 forælder"></Tag>
                        <Tag v-else severity="success" value="Bor hos 2 forældre"></Tag>
                    </template>
                </Column>
                <Column class="text-right" style="width:160px;min-width:160px;">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" v-tooltip.top="'Se dokument'" class="ml-3" @click="fn.goToDocument(slotProps.data)"></Button>
                        <Button v-tooltip.top="'Data indtastet i KOMiT'" class="p-button-success ml-3 p-button-icon-only" @click="fn.dataVerified(slotProps.data, true)">
                            <span class="pi pi-check p-button-icon" :class="{'pi-spin pi-spinner' : vm.verifyingDocumentId === slotProps.data.documentId}"></span>
                            <span class="p-button-label">&nbsp;</span>
                        </Button>
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div>
                        <div class="mb-3">
                            <div class="label mx-3 mb-3">Adresse</div>
                            <div v-if="slotProps.data.currentAddressData" class="bg-white border-round p-3">
                                <div v-if="fn.cleanString(slotProps.data.currentAddressData.street + slotProps.data.currentAddressData.zip + slotProps.data.currentAddressData.city) === fn.cleanString(slotProps.data.newAddressData.street + slotProps.data.newAddressData.zip + slotProps.data.newAddressData.city)">
                                    {{slotProps.data.newAddressData.street}}, {{slotProps.data.newAddressData.zip}} {{slotProps.data.newAddressData.city}}
                                    <i v-if="!slotProps.data.communeCode" class="pi pi-exclamation-triangle mx-2" v-tooltip.top="'Adressen er manuelt indtastet'"></i>
                                </div>
                                <div v-else>
                                    <div v-if="slotProps.data.currentAddressData.street" class="line-through">{{slotProps.data.currentAddressData.street}}, {{slotProps.data.currentAddressData.zip}} {{slotProps.data.currentAddressData.city}}</div>
                                    <div v-if="slotProps.data.newAddressData.street" class="flex align-items-center">
                                        <span class="highlight">{{ slotProps.data.newAddressData.street }}, {{ slotProps.data.newAddressData.zip }} {{ slotProps.data.newAddressData.city }}</span>
                                        <i v-if="!slotProps.data.communeCode" class="pi pi-exclamation-triangle mx-2" v-tooltip.top="'Adressen er manuelt indtastet'"></i>
                                        <Button icon="pi pi-copy"
                                                text
                                                rounded
                                                class="h-1rem w-2rem"
                                                @click="fn.copyToClipboard(`${slotProps.data.newAddressData.street}, ${slotProps.data.newAddressData.zip} ${slotProps.data.newAddressData.city}`)">
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="bg-white border-round p-3">
                                <div v-if="slotProps.data.newAddressData" class="highlight">{{slotProps.data.newAddressData.street}}, {{slotProps.data.newAddressData.zip}} {{slotProps.data.newAddressData.city}}</div>
                            </div>
                        </div>
                        <DataTable :value="slotProps.data.membersToCompare" responsiveLayout="scroll">
                            <Column field="memberType" header="Rolle">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.memberType ==='STUDENT_GRANT_STUDENT'">ELEV</div>
                                    <div v-else-if="slotProps.data.memberType ==='STUDENT_GRANT_FATHER'">FAR</div>
                                    <div v-else-if="slotProps.data.memberType ==='STUDENT_GRANT_MOTHER'">MOR</div>
                                    <div v-else-if="slotProps.data.memberType ==='STUDENT_GRANT_OTHER'">NY</div>
                                    <div v-else>UKENDT</div>
                                </template>
                            </Column>
                            <Column header="CPR-nummer">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.currentMemberData">
                                       
                                        <div v-if="slotProps.data.currentMemberData.personalIdEncrypted && slotProps.data.newMemberData.personalIdEncrypted && slotProps.data.currentMemberData.personalIdEncrypted.replace('-','') === slotProps.data.newMemberData.personalIdEncrypted.replace('-','')">
                                            {{slotProps.data.newMemberData.personalIdEncrypted}}                                            
                                        </div>
                                            <div v-else>
                                                <div v-if="slotProps.data.currentMemberData.personalIdEncrypted" class="line-through">{{slotProps.data.currentMemberData.personalIdEncrypted}}</div>
                                                <div v-if="slotProps.data.newMemberData.personalIdEncrypted" class="flex align-items-center">
                                                    <span class="highlight">{{slotProps.data.newMemberData.personalIdEncrypted}}</span>
                                                    <Button icon="pi pi-copy" class="w-2rem h-2rem" text rounded @click="fn.copyToClipboard(slotProps.data.newMemberData.personalIdEncrypted)"></Button>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    <div v-else>
                                        <div v-if="slotProps.data.newMemberData.personalIdEncrypted" class="flex align-items-center">
                                            <span class="highlight">{{slotProps.data.newMemberData.personalIdEncrypted}}</span>
                                            <Button icon="pi pi-copy" class="w-2rem h-2rem" text rounded @click="fn.copyToClipboard(slotProps.data.newMemberData.personalIdEncrypted)"></Button>
                                        </div>
                                    </div>
                                    
                                </template>
                            </Column>

                            <Column header="Navn">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.currentMemberData">
                                        <div v-if="fn.cleanString(slotProps.data.currentMemberData.firstname + slotProps.data.currentMemberData.lastname) === fn.cleanString(slotProps.data.newMemberData.firstname + slotProps.data.newMemberData.lastname)">
                                            {{slotProps.data.newMemberData.firstname + ' ' + slotProps.data.newMemberData.lastname}}
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.currentMemberData.firstname" class="line-through">{{slotProps.data.currentMemberData.firstname + ' ' + slotProps.data.currentMemberData.lastname}}</div>
                                            <div v-if="slotProps.data.newMemberData.firstname" class="flex align-items-center">
                                                <span class="highlight">{{ slotProps.data.newMemberData.firstname }} {{ slotProps.data.newMemberData.lastname }}</span>
                                                <Button icon="pi pi-copy"
                                                        class="w-2rem h-2rem"
                                                        text
                                                        rounded
                                                        @click="fn.copyToClipboard(`${slotProps.data.newMemberData.firstname} ${slotProps.data.newMemberData.lastname}`)">
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="slotProps.data.newMemberData.firstname" class="flex align-items-center">
                                            <span class="highlight">{{ slotProps.data.newMemberData.firstname }} {{ slotProps.data.newMemberData.lastname }}</span>
                                            <Button icon="pi pi-copy"
                                                    class="w-2rem h-2rem"
                                                    text
                                                    rounded
                                                    @click="fn.copyToClipboard(`${slotProps.data.newMemberData.firstname} ${slotProps.data.newMemberData.lastname}`)">
                                            </Button>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column header="E-mail">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.currentMemberData">
                                        <div v-if="!slotProps.data.newMemberData.email || fn.cleanString(slotProps.data.currentMemberData.email) === fn.cleanString(slotProps.data.newMemberData.email)">
                                            {{slotProps.data.newMemberData.email}}
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.currentMemberData.email" class="line-through">{{slotProps.data.currentMemberData.email}}</div>
                                            <div v-if="slotProps.data.newMemberData.email" class="flex align-items-center">
                                                <span class="highlight">{{ slotProps.data.newMemberData.email }}</span>
                                                <Button icon="pi pi-copy"
                                                        class="w-2rem h-2rem"
                                                        text
                                                        rounded
                                                        @click="fn.copyToClipboard(slotProps.data.newMemberData.email)">
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="slotProps.data.newMemberData.email" class="flex align-items-center">
                                            <span class="highlight">{{ slotProps.data.newMemberData.email }}</span>
                                            <Button icon="pi pi-copy"
                                                    class="w-2rem h-2rem"
                                                    text
                                                    rounded
                                                    @click="fn.copyToClipboard(slotProps.data.newMemberData.email)">
                                            </Button>
                                        </div>
                                    </div>
                                </template>
                            </Column>

                        </DataTable>
                        <div v-if="slotProps.data.documentAssets && slotProps.data.documentAssets.length">
                            <h5 class="mx-3 mt-5 mb-0">Ekstra dokumentation uploadet</h5>
                            <DataTable :value="slotProps.data.documentAssets" responsiveLayout="scroll">
                                <Column field="title" header="Filnavn"></Column>
                                <Column field="contentType" header="Type"></Column>
                                <Column header="Antivirus-scanning" headerStyle="width:80px" class="text-center">
                                    <template #body="slotProps">
                                        <div v-if="slotProps.data.scanningState !== 'SCANNED'">
                                            <i class="border-circle p-1 pi pi-clock text-white" style="background: #3B82F6; font-size: 20px; " v-tooltip.top="'Filen afventer antivirusscanning'"></i>
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.scanningResult === 'VIRUS_NOT_FOUND'">
                                                <i class="border-circle p-1 pi pi-check text-white" style="background: #39bc6c;font-size: 20px;" v-tooltip.top="'Ingen virus fundet'"></i>
                                            </div>
                                            <div v-else>
                                                <i class="border-circle p-1 pi pi-minus text-white" style="background: #ef4444; font-size: 20px;" v-tooltip.top="'Virus fundet i filen'"></i>

                                            </div>
                                        </div>
                                    </template>
                                </Column>
                                <Column headerStyle="width:80px" style="padding: 0.5rem 1rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-download" class="p-button-success" v-tooltip.top="'Hent dokument'" @click="fn.downloadDocumentAsset(slotProps.data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </template>
            </DataTable>

            <div class="mt-6 bg-white flex justify-content-between align-items-center pt-4 pb-2 mb-3 sticky top-0 z-1" style="border-bottom: 2px solid rgb(231, 231, 242);">
                <h3 class="mb-0">Indsendt data, som er indtastet i KOMiT</h3>
            </div>
            <DataTable :value="vm.documentStudentGrantMembersExtendedData"
                       v-model:expandedRows="expandedRows"
                       v-model:filters="studentGrantReviewedFilter"
                       responsiveLayout="scroll">
                <Column class="column-state">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.dataHasPendingChanges" class="icon-wrapper warning" v-tooltip.top="'Forskel i data'"><i class="pi pi-exclamation-triangle"></i></div>
                        <div v-else class="icon-wrapper success"><i class="pi pi-check"></i></div>
                    </template>
                </Column>
                <Column :expander="true" />
                <Column field="owner.externalId" header="Id" :sortable="true"></Column>
                <Column field="owner.firstname" header="Fornavn" :sortable="true"></Column>
                <Column field="owner.lastname" header="Efternavn" :sortable="true"></Column>
                <Column header="Kommune" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.communeCode}}: {{slotProps.data.communeName }}
                    </template>
                </Column>
                <Column header="Hjemme-boende børn" class="text-center" headerStyle="width:120px;min-width:120px;">
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.otherChildrenAmount > 0 || slotProps.data.otherChildrenNames" :value="slotProps.data.otherChildrenAmount" size="large" severity="warning" v-tooltip.top="slotProps.data.otherChildrenNames"></Badge>
                        <div v-else>{{slotProps.data.otherChildrenAmount}}</div>
                    </template>
                </Column>
                <Column header="Kræver yderligere dok." class="text-center column-state" headerStyle="width:115px;min-width:115px;">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.requiresDocumentation || slotProps.data.workedAbroad">
                            <i v-if="slotProps.data.documentAssets && slotProps.data.documentAssets.length" class="pi pi-check align-items-center inline-flex justify-content-center" style="background: #F59E0B; color: white; width: 36px; height: 36px; font-size: 1.4rem; border-radius: 50%;" v-tooltip.top="'Dokumentation uploadet, se den ved at trykke på pilen til venstre for elevnummeret'"></i>
                            <i v-else-if="slotProps.data.workedAbroad && !slotProps.data.requiresDocumentation" class="pi pi-info-circle align-items-center inline-flex justify-content-center" style="color: #3b82f6; width: 36px; height: 36px; font-size: 2rem;" v-tooltip.top="'Vær opmærksom på, at der er svaret ja til arbejdet i udlandet, men ikke ved indkomst i udlandet'"></i>
                            <i v-else class="pi pi-check align-items-center inline-flex justify-content-center" style="background: #EF4444; color: white; width: 36px; height: 36px; font-size: 1.4rem; border-radius: 50%;" v-tooltip.top="'Ingen dokumentation uploadet'"></i>
                        </span>
                        <i v-else class="pi pi-minus"></i>
                    </template>
                </Column>
                <Column header="Familie-situation" class="text-center column-state" headerStyle="width:140px;min-width:140px;">
                    <template #body="slotProps">
                        <Tag v-if="slotProps.data.familySetup ==='NEW_PARTNER'" severity="warning" value="Bor hos 1 forældre + Ny samlever/ægtefælle"></Tag>
                        <Tag v-else-if="slotProps.data.familySetup ==='SINGLE'" severity="info" value="Bor hos 1 forælder"></Tag>
                        <Tag v-else severity="success" value="Bor hos 2 forældre"></Tag>
                    </template>
                </Column>
                <Column class="text-right" style="width:160px;min-width:160px;">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" v-tooltip.top="'Se dokument'" class="ml-3" @click="fn.goToDocument(slotProps.data)"></Button>
                        <Button v-tooltip.top="'Fortryd markeret som indtastet'" class="p-button-warning ml-3 p-button-icon-only" @click="fn.dataVerified(slotProps.data, false)">
                            <span class="pi pi-undo p-button-icon" :class="{'pi-spin pi-spinner' : vm.verifyingDocumentId === slotProps.data.documentId}"></span>
                            <span class="p-button-label">&nbsp;</span>
                        </Button>
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div>
                        <div class="mb-3">
                            <div class="label mx-3 mb-3">Adresse</div>
                            <div v-if="slotProps.data.currentAddressData" class="bg-white border-round p-3">
                                <div v-if="fn.cleanString(slotProps.data.currentAddressData.street + slotProps.data.currentAddressData.zip + slotProps.data.currentAddressData.city) === fn.cleanString(slotProps.data.newAddressData.street + slotProps.data.newAddressData.zip + slotProps.data.newAddressData.city)">
                                    {{slotProps.data.newAddressData.street}}, {{slotProps.data.newAddressData.zip}} {{slotProps.data.newAddressData.city}}
                                    <i v-if="!slotProps.data.communeCode" class="pi pi-exclamation-triangle mx-2" v-tooltip.top="'Adressen er manuelt indtastet'"></i>
                                </div>
                                <div v-else>
                                    <div v-if="slotProps.data.currentAddressData.street" class="line-through">{{slotProps.data.currentAddressData.street}}, {{slotProps.data.currentAddressData.zip}} {{slotProps.data.currentAddressData.city}}</div>
                                    <div v-if="slotProps.data.newAddressData.street" class="highlight">
                                        {{slotProps.data.newAddressData.street}}, {{slotProps.data.newAddressData.zip}} {{slotProps.data.newAddressData.city}}
                                        <i v-if="!slotProps.data.communeCode" class="pi pi-exclamation-triangle mx-2" v-tooltip.top="'Adressen er manuelt indtastet'"></i>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="bg-white border-round p-3">
                                <div v-if="slotProps.data.newAddressData" class="highlight">{{slotProps.data.newAddressData.street}}, {{slotProps.data.newAddressData.zip}} {{slotProps.data.newAddressData.city}}</div>
                            </div>
                        </div>
                        <DataTable :value="slotProps.data.membersToCompare" responsiveLayout="scroll">
                            <Column field="memberType" header="Rolle">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.memberType ==='STUDENT_GRANT_STUDENT'">ELEV</div>
                                    <div v-else-if="slotProps.data.memberType ==='STUDENT_GRANT_FATHER'">FAR</div>
                                    <div v-else-if="slotProps.data.memberType ==='STUDENT_GRANT_MOTHER'">MOR</div>
                                    <div v-else-if="slotProps.data.memberType ==='STUDENT_GRANT_OTHER'">NY</div>
                                    <div v-else>UKENDT</div>
                                </template>
                            </Column>
                            <Column header="CPR-nummer">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.currentMemberData">
                                        <div v-if="slotProps.data.currentMemberData.personalIdEncrypted && slotProps.data.newMemberData.personalIdEncrypted && slotProps.data.currentMemberData.personalIdEncrypted.replace('-','') === slotProps.data.newMemberData.personalIdEncrypted.replace('-','')">
                                            {{slotProps.data.newMemberData.personalIdEncrypted}}
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.currentMemberData.personalIdEncrypted" class="line-through">{{slotProps.data.currentMemberData.personalIdEncrypted}}</div>
                                            <div v-if="slotProps.data.newMemberData.personalIdEncrypted" class="highlight">{{slotProps.data.newMemberData.personalIdEncrypted}}</div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="slotProps.data.newMemberData.personalIdEncrypted" class="highlight">{{slotProps.data.newMemberData.personalIdEncrypted}}</div>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Navn">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.currentMemberData">
                                        <div v-if="fn.cleanString(slotProps.data.currentMemberData.firstname + slotProps.data.currentMemberData.lastname) === fn.cleanString(slotProps.data.newMemberData.firstname + slotProps.data.newMemberData.lastname)">
                                            {{slotProps.data.newMemberData.firstname + ' ' + slotProps.data.newMemberData.lastname}}
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.currentMemberData.firstname" class="line-through">{{slotProps.data.currentMemberData.firstname + ' ' + slotProps.data.currentMemberData.lastname}}</div>
                                            <div v-if="slotProps.data.newMemberData.firstname" class="highlight">{{slotProps.data.newMemberData.firstname + ' ' + slotProps.data.newMemberData.lastname}}</div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="slotProps.data.newMemberData.firstname" class="highlight">{{slotProps.data.newMemberData.firstname + ' ' + slotProps.data.newMemberData.lastname}}</div>
                                    </div>
                                </template>
                            </Column>
                            <Column header="E-mail">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.currentMemberData">
                                        <div v-if="!slotProps.data.newMemberData.email || fn.cleanString(slotProps.data.currentMemberData.email) === fn.cleanString(slotProps.data.newMemberData.email)">
                                            {{slotProps.data.newMemberData.email}}
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.currentMemberData.email" class="line-through">{{slotProps.data.currentMemberData.email}}</div>
                                            <div v-if="slotProps.data.newMemberData.email" class="highlight">{{slotProps.data.newMemberData.email}}</div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="slotProps.data.newMemberData.email" class="highlight">{{slotProps.data.newMemberData.email}}</div>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                        <div v-if="slotProps.data.documentAssets && slotProps.data.documentAssets.length">
                            <h5 class="mx-3 mt-5 mb-0">Ekstra dokumentation uploadet</h5>
                            <DataTable :value="slotProps.data.documentAssets" responsiveLayout="scroll">
                                <Column field="title" header="Filnavn"></Column>
                                <Column field="contentType" header="Type"></Column>
                                <Column header="Antivirus-scanning" headerStyle="width:80px" class="text-center">
                                    <template #body="slotProps">
                                        <div v-if="slotProps.data.scanningState !== 'SCANNED'">
                                            <i class="border-circle p-1 pi pi-clock text-white" style="background: #3B82F6; font-size: 20px; " v-tooltip.top="'Filen afventer antivirusscanning'"></i>
                                        </div>
                                        <div v-else>
                                            <div v-if="slotProps.data.scanningResult === 'VIRUS_NOT_FOUND'">
                                                <i class="border-circle p-1 pi pi-check text-white" style="background: #39bc6c;font-size: 20px;" v-tooltip.top="'Ingen virus fundet'"></i>
                                            </div>
                                            <div v-else>
                                                <i class="border-circle p-1 pi pi-minus text-white" style="background: #ef4444; font-size: 20px;" v-tooltip.top="'Virus fundet i filen'"></i>

                                            </div>
                                        </div>
                                    </template>
                                </Column>
                                <Column headerStyle="width:80px" style="padding: 0.5rem 1rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-download" class="p-button-success" v-tooltip.top="'Hent dokument'" @click="fn.downloadDocumentAsset(slotProps.data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, inject, onMounted, defineProps } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from 'primevue/useconfirm';
    import Editor from '@tinymce/tinymce-vue';
    import { handlePastePreprocess } from '@/composables/paste-preprocess';
    import api from '@/composables/api';
    import gqlBuilder from '@/composables/gql-builder';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        documentName: String,
        documentTemplateId: String,
        batchId: String,
    });

    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const studentGrantNeedsReviewFilter = ref({
        hasBeenReviewed: { value: false, matchMode: FilterMatchMode.EQUALS },
    });
    const studentGrantReviewedFilter = ref({
        hasBeenReviewed: { value: true, matchMode: FilterMatchMode.EQUALS },
    });
    const store = inject('store');
    const apiUrl = inject('apiUrl');
    const expandedRows = ref([]);
    const toast = useToast();
    const confirm = useConfirm();
    const router = useRouter();
    const documentMenuRefs = ref([]);
    const documentMenu = ref([
        {
            label: 'Se dokument',
            icon: 'pi pi-eye',
            command: () => {
                fn.goToDocument(vm.currentDocument);
            },
        },
        {
            label: 'Send påmindelse',
            icon: 'pi pi-envelope',
            command: () => {
                fn.sendReminder(vm.currentDocument);
            },
            visible: () => {
                if (vm.currentDocument) {
                    return ['SENT', 'READY_TO_SIGN', 'INPUT_COMPLETED', 'SIGNING'].includes(vm.currentDocument.state);
                }
                return false;
            },
        },
        {
            label: 'Afslut dokument',
            icon: 'pi pi-check',
            command: () => {
                fn.confirmForceApprove(vm.currentDocument);
            },
            visible: () => {
                if (vm.currentDocument) {
                    return ['SENT', 'READY_TO_SIGN', 'INPUT_COMPLETED', 'SIGNING'].includes(vm.currentDocument.state) && !vm.currentDocument.hasSignatureOrderId;
                }
                return false;
            },
        },
        {
            label: 'Slet dokument',
            icon: 'pi pi-trash',
            class: 'menu-item-recycle-bin',
            command: () => {
                fn.confirmDelete(vm.currentDocument);
            },
        },
    ]);

    const vm = reactive({
        chartDocumentRecipientMembersData: {
            labels: ['Afsendt', 'Igang', 'Udfyldt', 'Afvist'],
            datasets: [
                {
                    data: [0, 0, 0, 0],
                    backgroundColor: ['#2196F3', '#3F51B5', '#39bc6c', '#EF4444'],
                    hoverBackgroundColor: ['#4ba9f5', '#6372c4', '#61c989', '#dc5959'],
                },
            ],
        },
        chartDocumentRecipientMembersOptions: {
            borderWidth: 3,
            borderRadius: 6,
            plugins: {
                legend: {
                    position: 'bottom',
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return `${context.label}: ${context.parsed}%`;
                        },
                    },
                },
            },
        },
        documentRecipientMembers: [],
        documentStudentGrantMembersExtendedData: [],
        documentName: '',
        isBatch: !!props.batchId,
        isStudentGrantDocument: false,
        showStudentGrantDetails: false,
        batchSettings: {},
        fetchingData: true,
        currentDocument: {},
        documentTemplate: {},
        submitting: false,
        busy: false,
        reminderTextChanged: false,
        verifyingDocumentId: null,
        visibleMenuId: null,
    });

    const fn = {
        showAll: () => {
            vm.isBatch = false;
            showDocumentStatsById(props.documentTemplateId);
        },
        goToDocument: (document) => {
            if (document) {
                const documentId = document.documentId || document.id;
                const documentUrl = document.state === 'PUBLISHED'
                    ? `${apiUrl}document/${store.auth.customerId}/file/${documentId}`
                    : `/document/${documentId}`;

                window.open(documentUrl, '_blank');
            }
        },
        gotoStudentGrantDataOverview: () => {
            vm.busy = true;
            api.query(gqlBuilder.documentTemplates.getMemberDataByDocumentTemplateId(store.auth.customerId, props.documentTemplateId))
                .then((response) => {
                    if (api.hasData(response)) {
                        vm.documentStudentGrantMembersExtendedData = api.getData(response);
                        vm.showStudentGrantDetails = true;
                    } else {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
                    }
                    vm.busy = false;
                });
        },
        confirmDelete: (document) => {
            vm.currentDocument = document;
            confirm.require({
                group: 'confirmDeleteDocument',
                header: 'Bekræft slet dokument',
                acceptLabel: 'Ja, slet',
                acceptClass: 'p-button-success p-button-lg',
                rejectLabel: 'Nej, luk',
                rejectClass: 'p-button-link p-button-lg',
                accept: () => {
                    fn.deleteDocument();
                },
            });
        },
        confirmForceApprove: (document) => {
            confirm.require({
                group: 'confirmForceApproveDocument',
                header: 'Bekræft at dokumentet skal afsluttes',
                acceptLabel: 'Ja, afslut',
                acceptClass: 'p-button-success p-button-lg',
                rejectLabel: 'Nej, luk',
                rejectClass: 'p-button-link p-button-lg',
                accept: () => {
                    api.postJson(`${apiUrl}documents/${document.documentId || document.id}?action=publish`).then(() => {
                        vm.documentRecipientMembers = vm.documentRecipientMembers.map(val => {
                            if (val.id === vm.currentDocument.id) {
                                val.state = "READY_TO_PUBLISH";
                            }
                            return val;
                        });
                        toast.add({ severity: 'success', summary: 'Dokumentet afsluttes', life: 5000 });
                    }).catch((error) => {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                    });
                },
            });
        },
        confirmDeleteSigner: (document, signer) => {
            if (document && signer && signer.member) {
                vm.confirmDeleteSignerMessage = `Er du sikker på, at du vil fjerne <strong>${signer.member.firstname} ${signer.member.lastname}</strong> som underskriver?`;
                confirm.require({
                    group: 'confirmDeleteSigner',
                    header: 'Bekræft fjernelse af underskriver',
                    acceptLabel: 'Ja, fjern',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, luk',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => {
                        api.del(`${apiUrl}documents/${document.documentId || document.id}/signers/${signer.member.id}`).then(() => {
                            //remove signer
                            document.signers = document.signers.filter(val => val.member.id !== signer.member.id);
                            toast.add({ severity: 'success', summary: `${signer.member.firstname} ${signer.member.lastname} fjernet som underskriver`, life: 5000 });
                        }).catch((error) => {
                            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                        });
                    },
                });
            }
        },
        deleteDocument: () => {
            vm.busy = true;
            api.del(`${apiUrl}documents/${vm.currentDocument.documentId || vm.currentDocument.id}`)
                .then((response) => {
                    if (response) {
                        vm.documentRecipientMembers = vm.documentRecipientMembers.filter(val => val.id !== vm.currentDocument.id);
                        toast.add({ severity: 'success', summary: 'Gennemført', detail: `Dokument slettet for ${vm.currentDocument.owner.firstname} ${vm.currentDocument.owner.lastname}`, life: 3000 });
                    } else {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
                    }
                    vm.busy = false;
                    vm.currentDocument = {};
                })
                .catch((error) => {
                    toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                    vm.busy = false;
                });
        },
        sendReminder: (document) => {
            vm.reminderTextChanged = false;
            vm.currentDocument = document;
            api.query(gqlBuilder.documentTemplates.getById(store.auth.customerId, props.documentTemplateId))
                .then((response) => {
                    if (api.hasData(response)) {
                        vm.documentTemplate = api.getData(response);
                    }
                });
            confirm.require({
                group: 'confirmSendReminder',
                message: `Er du sikker på, at du vil sende en påmindelse til ${document.owner.firstname} ${document.owner.lastname} og/eller de forældre, der mangler at svare?`,
                header: 'Bekræft venligst',
                acceptLabel: 'Ja, send påmindelse',
                rejectLabel: 'Nej, luk',
                accept: () => {
                    vm.submitting = true;

                    const documentSettings = {
                        reminderEmailText: vm.reminderTextChanged ? vm.documentTemplate.reminderEmailText : undefined,
                        isDefaultDocumentTemplateValues: vm.reminderTextChanged ? vm.documentTemplate.reminderEmailTextRemember || false : undefined,
                        forceRemindAllSigners: vm.documentTemplate.forceRemindAllSigners,
                    };

                    api.query(gqlBuilder.documents.sendReminder(store.auth.customerId, document.documentId || document.id, documentSettings))
                        .then((response) => {
                            if (api.hasData(response)) {
                                const message = vm.documentTemplate.forceRemindAllSigners
                                    ? 'Påmindelse sendt til alle'
                                    : 'Påmindelse sendt til de, som endnu ikke har svaret.';
                                toast.add({ severity: 'success', summary: 'Gennemført', detail: message, life: 4000 });
                                vm.submitting = false;
                            } else {
                                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
                                vm.submitting = false;
                            }
                        })
                        .catch((error) => {
                            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                            vm.submitting = false;
                        });

                    vm.currentDocument = {};
                },
            });
        },
        sendReminders: () => {
            vm.reminderTextChanged = false;
            vm.documentTemplate = {};
            api.query(gqlBuilder.documentTemplates.getById(store.auth.customerId, props.documentTemplateId))
                .then((response) => {
                    if (api.hasData(response)) {
                        vm.documentTemplate = api.getData(response);
                    }
                });
            confirm.require({
                group: 'confirmSendReminder',
                message: 'Er du sikker på, at du vil sende en påmindelse til alle, der mangler at svare?',
                header: 'Bekræft venligst',
                acceptLabel: 'Ja, send påmindelse',
                rejectLabel: 'Nej, luk',
                accept: () => {
                    vm.submitting = true;

                    const documentSettings = {
                        reminderEmailText: vm.reminderTextChanged ? vm.documentTemplate.reminderEmailText : undefined,
                        isDefaultDocumentTemplateValues: vm.reminderTextChanged ? vm.documentTemplate.reminderEmailTextRemember || false : undefined,
                        forceRemindAllSigners: vm.documentTemplate.forceRemindAllSigners,
                    };

                    api.query(gqlBuilder.documentTemplates.sendReminders(store.auth.customerId, props.documentTemplateId, documentSettings))
                        .then((response) => {
                            if (api.hasData(response)) {
                                const message = vm.documentTemplate.forceRemindAllSigners
                                    ? 'Påmindelse sendt til alle'
                                    : 'Påmindelse sendt til de, som endnu ikke har svaret.';
                                toast.add({ severity: 'success', summary: 'Gennemført', detail: message, life: 4000 });
                                vm.submitting = false;
                            } else {
                                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message }); 
                                vm.submitting = false;
                            }
                        })
                        .catch((error) => {
                            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                            vm.submitting = false;
                        });
                },
            });
        },
        dataVerified: (data, reviewed) => {
            vm.verifyingDocumentId = data.documentId;
            api.mutation(gqlBuilder.documents.reviewedData(store.auth.customerId, data.documentId, reviewed)).then((response) => {
                if (api.hasData(response)) {
                    data.hasBeenReviewed = reviewed;
                }
                vm.verifyingDocumentId = null;
            });
        },
        downloadDocumentAsset: (fileData) => {
            window.open(fileData.url, '_blank');
        },
        downloadDocumentData: (documentTemplateId) => {
            window.open(apiUrl + 'documenttemplate/' + documentTemplateId + '/customer/' + store.auth.customerId, '_blank');
        },
        cleanString: (str) => {
            if (str) {
                return str.replace(/[\s,.]/g, '').toLowerCase();
            }
            return str;
        },
        goBack() {
            if (router) {
                router.back();
            }
        },
        toggleDocumentMenu: (index, event, document) => {
            vm.currentDocument = document;
            const menuRef = documentMenuRefs.value[index];
            if (menuRef) {
                menuRef.toggle(event);
            }
        },
        copyToClipboard(textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => toast.add({ severity: 'success', summary: 'Kopieret til udklipsholderen', life: 5000 }))
                .catch(err => console.error('Kunne ikke kopiere: ', err));
        },
    };

    onMounted(() => {
        if (props.batchId) {
            api.query(gqlBuilder.batches.getById(store.auth.customerId, props.batchId)).then((response) => {
                if (api.hasData(response)) {
                    var data = api.getData(response)[0];
                    vm.documentRecipientMembers = setSigningStateOnRecipients(data.documents);
                    vm.batchSettings.sendToMemberOnly = data.sendToMemberOnly;
                    vm.batchSettings.sendToMemberRelationsOnly = data.sendToMemberRelationsOnly;
                    setCompletedPercentage(vm.documentRecipientMembers);

                    vm.fetchingData = false;
                }
            }).catch((error) => {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                vm.fetchingData = false;
            });
        } else if (props.documentTemplateId) {
            showDocumentStatsById(props.documentTemplateId);
        }
    });


    // PRIVATE FUNCTIONS
    function setSigningStateOnRecipients(documents) {
        for (var i = 0, l = documents.length; i < l; i++) {
            var document = documents[i];
            if (document.signers.length) {
                document.signingInfo = '(' + document.signers.filter(item => item.state !== 'UNSIGNED').length + '/' + document.signers.length + ')';
            }

            for (var j = 0, recipientsLength = document.recipients.length; j < recipientsLength; j++) {
                var recipient = document.recipients[j];

                for (var k = 0, signersLength = document.signers.length; k < signersLength; k++) {
                    var signer = document.signers[k];

                    if (signer.member.id === recipient.member.id) {
                        recipient.signingState = signer.state;
                        break;
                    }
                }
            }
        }

        return documents;
    }
    function setCompletedPercentage(recipientMembers) {
        var completed = 0;
        var inProgress = 0;
        var declined = 0;
        for (var i = 0; i < recipientMembers.length; ++i) {
            if (recipientMembers[i].state === 'PUBLISHED' || recipientMembers[i].state === 'READY_TO_PUBLISH' || recipientMembers[i].state === 'READY_TO_SENDER_APPROVE') {
                completed++;
            }
            else if (recipientMembers[i].state === 'INPUT_COMPLETED' || recipientMembers[i].state === 'READY_TO_SIGN' || recipientMembers[i].state === 'SIGNING') {
                inProgress++;
            }
            else if (recipientMembers[i].state === 'DECLINED') {
                declined++;
            }
        }
        var completedPercentage = (completed / recipientMembers.length) * 100;
        var inProgressPercentage = (inProgress / recipientMembers.length) * 100;
        var declinedPercentage = (declined / recipientMembers.length) * 100;

        vm.chartDocumentRecipientMembersData.datasets[0].data[3] = declinedPercentage.toFixed(2);
        vm.chartDocumentRecipientMembersData.datasets[0].data[1] = inProgressPercentage.toFixed(2);
        vm.chartDocumentRecipientMembersData.datasets[0].data[2] = completedPercentage.toFixed(2);
        vm.chartDocumentRecipientMembersData.datasets[0].data[0] = Math.round(100 - (completedPercentage + inProgressPercentage + declinedPercentage));
        vm.chartDocumentRecipientMembersData.completed = Math.ceil(completedPercentage + declinedPercentage);
        vm.chartDocumentRecipientMembersData.missing = Math.ceil(100 - vm.chartDocumentRecipientMembersData.completed);

    }
    function showDocumentStatsById(id) {
        vm.fetchingData = true;
        api.query(gqlBuilder.documentTemplates.getRecipientMembersByDocumentTemplateId(store.auth.customerId, id)).then((response) => {
            if (api.hasData(response) && !response.errors) {
                var data = api.getData(response);
                vm.isStudentGrantDocument = data.documentTemplateType === 'STUDENT_GRANT_DOCUMENT';
                vm.documentName = data.documentName;
                vm.documentRecipientMembers = setSigningStateOnRecipients(data.documents);
                setCompletedPercentage(vm.documentRecipientMembers);

            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
            }
            vm.fetchingData = false;
        });
    }


</script>
<style scoped>
    .highlight {
        display: inline-block;
        background-color: #ffd500;
    }

    .label {
        color: rgba(0, 0, 0, 0.4);
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
    }
</style>
<style>
    .menu-item-recycle-bin .p-menuitem-icon, .menu-item-recycle-bin .p-menuitem-text {
        color: red !important;
    }
</style>
