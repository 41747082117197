<template>

    <div class="navigation d-print-none" :class="[{ 'is-open': vm.isMobileMenuOpen}, vm.themeClass]">
        <a href="/admin" class="branding align-items-center flex px-3">
            <img src="../assets/skoleadmin-text-horizontal.svg" style="max-width:190px;width:100%" class="lg:hidden" alt="Skoleadmin Logo" />
            <img src="../assets/skoleadmin-text-purple.svg" style="max-width:120px" class="hidden lg:block" alt="Skoleadmin Logo" />
            <img v-if="store.config && store.config.partnerLogoUrl" style="max-width:80px" class="hidden lg:block" :alt="store.config.partnerName" v-bind:src="store.config.partnerLogoUrl" />
        </a>
        <div class="menu" @click="vm.isMobileMenuOpen = false">
            <slot></slot>
            <div class="nav-bottom">
                <div class="text-center" v-if="store.auth.isAuthenticated">
                    Logget ind som:<br />
                    <strong class="px-2">{{store.auth.currentUser.firstname + ' ' + store.auth.currentUser.lastname}}</strong><br />
                    <Button label="Log ud" icon="pi pi-power-off" class="btn-signout p-button-sm p-button-outlined mb-3 mt-2" @click="fn.signOut"></Button>
                </div>
                <div class="build-number">
                    24.8.2 (1115)<br />
                    <a href="https://skoleadmin.dk/privatlivspolitik.pdf">Privatlivspolitik</a>
                </div>
            </div>
        </div>
        <button class="hamburger hamburger--spin" type="button" @click="vm.isMobileMenuOpen = !vm.isMobileMenuOpen">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div>
    
</template>

<script setup>
    import { defineProps, reactive } from 'vue';
    import { inject } from 'vue';
    import api from '@/composables/api';
    
    const props = defineProps({
        theme: String
    })

    const store = inject('store');
    const apiUrl = inject('apiUrl');    
    const vm = reactive({
        isMobileMenuOpen: false,
        themeClass: props.theme ? 'theme-' + props.theme : ''
    });


    const fn = {
        signOut: () => {
            api.redirect(apiUrl + 'authentication/signout');
        }
    };

</script>


<style scoped lang="scss"></style>
